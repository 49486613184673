import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { TemplatePortfolio } from '../templates';
import { Lead, Heading } from '@arturpol/style-guide/src/components';
import { Link } from '../components';
export const meta = {
  title: 'Portfolio',
  type: 'page',
  hint: 'Check our works',
  order: 32,
  isPartiallyActive: true,
  seoTitle: 'Portfolio - Made with Human Design',
  seoDescription: 'Portfolio of our latest projects made with the human design principle. See examples of our SaaS, web and mobile application design.'
};
export const banner = {
  heading: 'Products with a human touch',
  content: <>
            <Lead mdxType="Lead">We could write a book about <Link url="/about/" title="Learn about our design process" mdxType="Link">workflow</Link>, tools, and genius methods, we use in human-centered design, but we believe that the proof of the recipe is the dinner on the table and the taste in your mouth.</Lead>
        </>,
  image: {
    alt: 'Products with a human touch'
  }
};
export const _frontmatter = {};
const layoutProps = {
  meta,
  banner,
  _frontmatter
};
const MDXLayout = TemplatePortfolio;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      